import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class WhyShould extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="whyshould pt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3">Why Should You opt for</span>
              Decentralized Exchange development?
              </h2>
              <p className="pharagraph">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/why-should-start-defi-exchange.svg" alt="Why Should Start NFT Marketplace image1" />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph">Decentralized exchanges work on blockchain technology, which makes them very secure because they don’t rely on a single point of control.</li>
                <li className="pharagraph">Unlike traditional exchanges, DEXs don’t usually ask for much personal information, which helps protect your trader’s privacy and reduce the chance of their data being stolen.
                </li>
                <li className="pharagraph">Anyone with internet access can use a DEX, making trading and financial services available to people in places where traditional banking isn’t accessible.</li>
                <li className="pharagraph">DEXs allow direct transactions between blockchain users, reducing the need to trust a middleman or central authority.</li>
                <li className="pharagraph">They also generally have lower fees as they cut out many intermediaries. With that, you can attract many users because they usually only pay network fees.</li>
                <li className="pharagraph">Most DEXs are open-source, this transparency lets users check and trust the platform themselves, without needing a central authority.</li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould