import React from 'react'



const WeProvide =()=> {

    return (
      <section className="secureof lucrative centeralign pt-100 mb-0">
        <div className="container">
        <h2 className="heading-h2 text-center">Decentralized Exchange Development Services <span className="bluecolor heading-h3">We Provide</span></h2>
        <p className='text-center'>
        Explore our top-notch decentralized exchange development services that prioritize enhancing your exchange business. From DEX consulting services to Market-Making services, we bring you the world of innovation with our customizable Decentralized exchange development solutions. 
        </p>
          <div className="factor">
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/dex-consult-service.png" alt="DEX Consulting Services" />
              <span>DEX Consulting Services</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/dex-app-development.png" alt="DEX App Development" /><span>DEX App Development
                </span></p>
            </div>
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/whitelabel-dex.png" alt="White Label DEX Software" /><span>White Label DEX Software</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/integration-service.png" alt="Integration Services and Solutions" /><span>Integration Services and Solutions</span></p>
            </div>
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/maintanance-service.png" alt="Upgrades and Maintenance Services" /><span>Upgrades and Maintenance Services</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/dex-market-making.png" alt="DEX Market-Making Services" /><span>DEX Market-Making Services</span></p>
            </div>
          </div>
        </div>
      </section>
    )
}

export default WeProvide