import React from 'react'



class UsecaseOf extends React.Component {


  render() {

    return (
      <section className="usecase">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Advantages</span> of our</span> Decentralized Exchange Development</h3>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/usecase-of-defi-exchange.svg" alt="Use Case of DeFi Exchange Development image1" />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph" ><b>Asset management -</b>  High-value assets can be tokenized and traded on our DEX, with all asset properties and values encoded in smart contracts.
              </p>
              <p className="pharagraph" ><b>Compliance -</b> Legal documents and compliance records can be securely exchanged between stakeholders through our DEX.
              </p>
              <p className="pharagraph" ><b>Copyright contracts -</b>  By utilizing smart contracts, creators can ensure that their intellectual property rights are preserved and honored, with automatic enforcement of terms.
              </p>
              <p className="pharagraph" ><b>Payment solutions -</b>  The DEX platform we create offers secure and efficient transactions, making it ideal for both personal and business payments.
              </p>
              <p className="pharagraph" ><b>Digital identity -</b>  Once set up in a smart contract, these identities cannot be changed, making the verification process secure and trustworthy.
              </p>
              <p className="pharagraph" ><b>Decentralized Loan -</b> Our DEX enables users to securely lend and borrow assets through smart contracts with interest rates and repayment schedules.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default UsecaseOf