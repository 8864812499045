import React from 'react'

const WeOffer = () => {


  return (
    <section className="choosedesire ychoose otc various mb-0 pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2">White Label Decentralized Exchange Solutions <span className="bluecolor">We offer</span></h2>
            <p className='text-center'>We offer white label decentralized exchange solutions with an ultra layer of customization to create DEX with industry-first features in a quick time. As a reputed white label decentralized exchange development company, we take your requirements in depth and create a DEX platform with contemporary revenue potential. Our White Label DEX Solutions are ideal, whether you want to build an exchange like the existing one or set up a DEX with your business idealogy. </p>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/pancakeswap.png" alt="White Label of PancakeSwap" />
                </div>
                <h3>
                White Label of PancakeSwap
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                The White Label solution of PancakeSwap allows you to build a DEX platform with the same features as the existing Pancakeswap exchange. With our customizable White Label DEX software Pancakeswap, you can scale up your profit potential with fascinating features like AMM, Yield Farming, Liquidity Pool, and more.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/uniswap.png" alt="White Label of Uniswap" />
                </div>
                <h3>
                White Label of Uniswap
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                The White Label solution of Uniswap empowers your vision of launching a scalable and robust DEX platform similar to Uniswap Exchange. Leverage the advantages of integration possibilities, seamless token transfer and AMM features to build a competitive decentralized exchange with the White Label uniswap solution.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/dydx.png" alt="White Label of dYdX" />
                </div>
                <h3>
                White Label of dYdX
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                Our White Label solution of dYdX is perfectly tuned to offer different decentralized trading options such as derivative, margin, and perpetual contract trading. Let your users experience layer 2 scalability with high performance by creating a DEX with our White Label dYdX software.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/curve.png" alt="White Label of Curve Finance" />
                </div>
                <h3>
                White Label of Curve Finance
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                Coinsclone offers a tailored White Label Curve Finance software to create DEX with lower slippage and multi-currency support. Our Curve clone software has smart features like DAO integration, and incentive distribution, and can uplift your business values globally in a short span.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/1inch.png" alt="White Label of 1 inch Exchange" />
                </div>
                <h3>
                White Label of 1 inch Exchange
                </h3>
              </div>
              <div className="content-rght">
               
                <p className="pharagraph">
                Our White Label solution of 1 inch helps you elevate your DEX capabilities with aggregator services that combine liquidity from other exchanges. Our software is designed to deliver industry-first DEX features like gas fee optimization, multi-wallet support, and more to meet your business goals effectively.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/binance.png" alt="White Label of Binance DEX" />
                </div>
                <h3>
                White Label of Binance DEX
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                Our Binance DEX White Label solution is a ready-made decentralized exchange solution that can excel in both performance and scalability together. We offer White Label Binance DEX software that supports different trading options features with a simplified UI/UX interface and next-generation security features.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>  
  )
}

export default WeOffer