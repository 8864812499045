import React ,{useEffect, useState} from 'react'
import { StaticImage } from 'gatsby-plugin-image'




const HowOur = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <section className="how-our">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h2 className="heading-h2"><span className="heading-h3">Workflow Of Our 
          </span>
          Decentralized Exchange Development Software
          </h2>
        </div>
        { isDesktop && (
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1126.192" height="1622.064" viewBox="0 0 1126.192 1622.064">
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectangle_8833" data-name="Rectangle 8833" width="348.812" height="318.574" fill="none" />
            </clipPath>
            <clipPath id="clip-path-2">
              <rect id="Rectangle_8832" data-name="Rectangle 8832" width="335.907" height="243.661" transform="translate(0 0)" fill="none" />
            </clipPath>
          </defs>
          <g id="Flow" transform="translate(-386.214 -4999.935)">
            <g id="Text">
              <g id="Ellipse_1376" data-name="Ellipse 1376" transform="translate(426.954 5027.596)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <circle cx={108} cy={108} r={108} stroke="none" />
                <circle cx={108} cy={108} r={107} fill="none" />
              </g>
              <g id="Admin" transform="translate(1163.594 5004.809)">
                <path id="Path_44" data-name="Path 44" d="M242.422,238.632H161.513l-21.36-50.326h80.909Z" transform="translate(-79.679 -107.055)" fill="#131f2d" />
                <path id="Path_45" data-name="Path 45" d="M245.673,238.632H164.763L143.4,188.307h80.909Z" transform="translate(-81.527 -107.055)" fill="#131f2d" />
                <path id="Path_46" data-name="Path 46" d="M248.321,238.823H173.135l-19.849-46.766h75.186Z" transform="translate(-87.145 -109.187)" fill="#00a3fe" />
                <path id="Path_47" data-name="Path 47" d="M297.913,306.559H190.3l-.647-1.618H297.913Z" transform="translate(-107.822 -173.363)" fill="#131f2d" />
                <g id="Group_27453" data-name="Group 27453">
                  <g id="Group_27452" data-name="Group 27452" clipPath="url(#clip-path)">
                    <path id="Path_48" data-name="Path 48" d="M343.205,308.754H5.606c-3.083,0-5.606,3.414-5.606,7.587V486.253c0,4.173,2.523,7.586,5.606,7.586h337.6c3.084,0,5.606-3.413,5.606-7.586V316.341c0-4.173-2.522-7.587-5.606-7.587" transform="translate(0 -175.531)" fill="#b8c1c9" />
                    <path id="Path_49" data-name="Path 49" d="M345.559,351.9H50.72c-2.726,0-4.957,3.071-4.957,6.824V511.544c0,3.753,2.231,6.823,4.957,6.823H345.559c2.727,0,4.957-3.07,4.957-6.823V358.723c0-3.753-2.23-6.824-4.957-6.824" transform="translate(-26.017 -200.06)" fill="#657285" />
                    <path id="Path_50" data-name="Path 50" d="M492.942,41.263a9.826,9.826,0,0,0,.494-2.838c.368-12.148,3.131-20.215-9.3-21.3s-14.991,3.48-16.1,7.506.476,24.507,5.536,25.052a34.889,34.889,0,0,0,3.946-.112c.108,1.335.3,3.646.506,6.192.4,4.865.88,10.565,1.019,11.874l13.753-.676,7.266-9.047a40.093,40.093,0,0,1-7.119-16.652" transform="translate(-265.894 -9.645)" fill="#ff7955" />
                    <path id="Path_51" data-name="Path 51" d="M364.276,695.2s-.961,15.515-3.963,15.738c-.4.029-15.224.029-15.224.029l-18.924-.109s.123-4.56,5.655-5.524,14.791-4.852,18.142-12.163c3.525-7.692,14.315,2.03,14.315,2.03" transform="translate(-185.429 -392.396)" fill="#131f2d" />
                    <path id="Path_52" data-name="Path 52" d="M465.446,385.39a115.452,115.452,0,0,1-9.048,12.944c-9.953,12.567-20.79,22.928-22.677,32.721a44.228,44.228,0,0,1-1.75,6.269c-.788,2.243-1.762,4.6-2.887,7.041-4.224,9.175-10.541,19.456-16.848,28.8-10.9,16.156-21.156,29.636-21.156,29.636l-18.8-.8c.753-1.884,8.953-24.191,19.923-51.451,4.455-11.066,9.192-22.838,13.762-34.189q.913-2.275,1.82-4.522c1.4-3.474,2.772-6.893,4.115-10.224,10.3-25.6,18.506-45.979,18.506-45.979s41.169-15.6,45.444-5.49c3.525,8.332.655,16.495-10.4,35.243" transform="translate(-211.646 -197.127)" fill="#016eb9" />
                    <path id="Path_53" data-name="Path 53" d="M502.422,597.785s13.078,8.4,11.809,11.133c-.17.363-7.4,13.308-7.4,13.308l-9.322,16.47s-3.92-2.331-2.065-7.632,2.974-15.279-1.776-21.768c-5-6.828,8.75-11.51,8.75-11.51" transform="translate(-280.038 -339.85)" fill="#131f2d" />
                    <path id="Path_54" data-name="Path 54" d="M478.341,99.04c.3-.109,18.589-4.487,20.511-3.739s2.884,4.594,2.884,4.594l-29.8,5.128s2.564-4.594,6.409-5.983" transform="translate(-268.3 -54.131)" fill="#131f2d" />
                    <path id="Path_55" data-name="Path 55" d="M468.591,13.919s4.817,13.442,7.505,12.1,5.153-2.465,5.825,1.792-2.016,10.082,6.946,8.514,15.63-27.492,12.1-31.141c-10.193-10.53-46.376-3.7-32.373,8.738" transform="translate(-264.594 0)" fill="#131f2d" />
                    <path id="Path_56" data-name="Path 56" d="M397.558,351.273S346.6,383,340.835,404.631s68.74,67.779,68.74,67.779,8.173-7.211,9.614-12.5c0,0-33.679-40.35-40.985-49.4-4.487-5.554,66.341-19.229,74.994-32.69s5.555-24.784,5.555-24.784Z" transform="translate(-193.589 -199.704)" fill="#00a3fe" />
                    <path id="Path_57" data-name="Path 57" d="M292.319,286.76s-6.191-5.823-9.141-5.209-9.075,4.569-10.05,5.7-1.852,2.966.62,3.407,2.6.5,2.6.5,5.03-1.667,5.979-1.377,4.238,3.563,6.921,2.088,3.072-5.109,3.072-5.109" transform="translate(-154.718 -160.04)" fill="#ff7955" />
                    <path id="Path_58" data-name="Path 58" d="M516.324,212.829c-4.919,5.813-46.775,4.3-59.04-.609a8.664,8.664,0,0,1-2.157-1.16c-7.211-5.769,9.063-10.837-9.134-54.8a.559.559,0,0,1-.026-.064c-11.438-32.548,3.618-42,8.9-46.8,0,0,25.556-7.766,39.5-3.446,18.107,5.609,27.241,100.633,21.953,106.882" transform="translate(-251.159 -59.489)" fill="#364154" />
                    <path id="Path_59" data-name="Path 59" d="M477.827,255.6c-.827,5.986-5.643,20.755-15.027,20.97a8.652,8.652,0,0,1-2.157-1.16c-7.211-5.769,9.063-10.836-9.134-54.8a.69.69,0,0,1-.026-.064c3.042-2.09,7.971-4.458,11.173-.837,4.915,5.554,16.024,29.7,15.171,35.895" transform="translate(-256.675 -123.838)" fill="#131f2d" />
                    <path id="Path_60" data-name="Path 60" d="M377.6,117.945s-7.6,4.074-13.01,17.3c-5.594,13.688-13.017,44.371-15.061,45.706s-32.734,6.174-41.872,9.729c0,0-3.589,6.432-.2,9.09s44.5,1.2,46.987.551c20.749-5.373,31.652-46.313,34.128-52.6S377.6,117.945,377.6,117.945" transform="translate(-173.965 -67.054)" fill="#364154" />
                    <path id="Path_61" data-name="Path 61" d="M531.023,564.722V543.045h22.136a1.573,1.573,0,0,0,1.568-1.568v-5.9a1.572,1.572,0,0,0-1.568-1.568H531.023V464.155a4.18,4.18,0,0,0-4.168-4.168h-4.472a4.181,4.181,0,0,0-4.168,4.168v69.856H496.078a1.572,1.572,0,0,0-1.567,1.568v5.9a1.572,1.572,0,0,0,1.567,1.568h22.136v21.677c-16.349,1.236-30.324,7.758-34,15.362h79.928c-3.674-7.6-16.773-14.126-33.121-15.362" transform="translate(-275.284 -261.51)" fill="#131f2d" />
                    <path id="Path_62" data-name="Path 62" d="M555.992,446H450.231a3.856,3.856,0,0,0-3.845,3.845V471a3.857,3.857,0,0,0,3.845,3.845h105.76A3.857,3.857,0,0,0,559.837,471V449.842A3.856,3.856,0,0,0,555.992,446" transform="translate(-253.777 -253.556)" fill="#b8c1c9" />
                  </g>
                </g>
              </g>
              <g id="Line">
                <line id="Line_204" data-name="Line 204" y2={199} transform="translate(646.793 5210.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_205" data-name="Line 205" y2={73} transform="translate(646.793 5482.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_232" data-name="Line 232" x2="70.767" transform="translate(1121.137 5906.768) rotate(-90)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_233" data-name="Line 233" x2="34.317" transform="translate(1121.137 5906.768)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_207" data-name="Line 207" y2={123} transform="translate(677.5 5587.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_236" data-name="Line 236" x2={100} transform="translate(504.454 5740.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_237" data-name="Line 237" y2={96} transform="translate(504.454 5740.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_238" data-name="Line 238" x1={66} transform="translate(504.454 5836.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_231" data-name="Line 231" y1="226.137" transform="translate(895 5836) rotate(-90)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_234" data-name="Line 234" y2={627} transform="translate(971 5308.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_239" data-name="Line 239" y1={141} transform="translate(807.454 5446.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_241" data-name="Line 241" x1={77} transform="translate(730.454 5590.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_240" data-name="Line 240" x1={273} transform="translate(807.454 5446.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_242" data-name="Line 242" x1={167} transform="translate(971.454 5638.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_235" data-name="Line 235" x1="152.546" transform="translate(818.454 5308.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_220" data-name="Line 220" y1={131} transform="translate(1163.5 5279.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_221" data-name="Line 221" y1={126} transform="translate(1265.5 5501.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_222" data-name="Line 222" y1="194.171" transform="translate(1265.5 5681.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_223" data-name="Line 223" x1={159} transform="translate(683.454 5968.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_226" data-name="Line 226" x2="167.505" transform="translate(1097.995 5968.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_227" data-name="Line 227" x2="86.497" transform="translate(775.003 6102.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_229" data-name="Line 229" x1="91.21" transform="translate(503.793 6102.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_224" data-name="Line 224" y1="100.829" transform="translate(683.793 5968.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_225" data-name="Line 225" y1="340.829" transform="translate(1265.161 5968.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_228" data-name="Line 228" y1="451.873" transform="translate(861.161 6102.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_230" data-name="Line 230" y1="374.829" transform="translate(504.151 6102.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              </g>
              <g id="Dots">
                <circle id="Ellipse_134" data-name="Ellipse 134" cx="7.5" cy="7.5" r="7.5" transform="translate(640 5203)" fill="#4b9aff" />
                <circle id="Ellipse_1369" data-name="Ellipse 1369" cx="7.5" cy="7.5" r="7.5" transform="translate(670 5621)" fill="#4b9aff" />
                <circle id="Ellipse_1373" data-name="Ellipse 1373" cx="7.5" cy="7.5" r="7.5" transform="translate(1156 5272.095)" fill="#ff6464" />
                <circle id="Ellipse_1374" data-name="Ellipse 1374" cx="7.5" cy="7.5" r="7.5" transform="translate(1258 5494.095)" fill="#ff6464" />
              </g>
              <g id="User" transform="translate(435.361 4999.935)">
                <g id="Group_27451" data-name="Group 27451" transform="translate(0 0)" clipPath="url(#clip-path-2)">
                  <path id="Path_1" data-name="Path 1" d="M1279.945,916.1l.158,0a2.141,2.141,0,0,0,2.164-2.29l-8.6-85.906-7.506.67,12.351,86.367a1.4,1.4,0,0,0,1.435,1.161" transform="translate(-1184.128 -672.435)" fill="#acc7eb" />
                  <path id="Path_2" data-name="Path 2" d="M1648.575,916.1l-.158,0a2.141,2.141,0,0,1-2.164-2.29l8.6-85.906,7.506.67-12.351,86.367a1.4,1.4,0,0,1-1.435,1.161" transform="translate(-1635.585 -672.435)" fill="#c8dbf4" />
                  <path id="Path_3" data-name="Path 3" d="M956.777,916.1l.158,0a2.141,2.141,0,0,0,2.164-2.29L950.5,827.9l-7.506.67,12.351,86.367a1.4,1.4,0,0,0,1.435,1.161" transform="translate(-800.275 -672.435)" fill="#bad4f4" />
                  <path id="Path_4" data-name="Path 4" d="M1317.829,351.74l-15.269-75.4c-1.96-10.209,6.5-19.544,17.483-19.293l29.447.672A23.261,23.261,0,0,1,1371.3,274.51l22.339,77.23Z" transform="translate(-1302.277 -208.774)" fill="#bad4f4" />
                  <path id="Path_5" data-name="Path 5" d="M1268.637,351.74l-15.269-75.4c-1.959-10.209,6.5-19.544,17.484-19.293l29.447.672a23.261,23.261,0,0,1,21.814,16.792l22.339,77.23Z" transform="translate(-1243.848 -208.774)" fill="#acc7eb" />
                  <path id="Path_6" data-name="Path 6" d="M1044.523,775.1h116.5a4.268,4.268,0,0,0,4.256-4.256v-5.241a4.268,4.268,0,0,0-4.256-4.256H1040.267v9.5a4.268,4.268,0,0,0,4.256,4.256" transform="translate(-1024.715 -618.385)" fill="#c8dbf4" />
                  <path id="Path_7" data-name="Path 7" d="M981.871,775.1h74.268a3.438,3.438,0,0,0,3.428-3.428v-6.9a3.438,3.438,0,0,0-3.428-3.428H981.871a3.438,3.438,0,0,0-3.428,3.428v6.9a3.439,3.439,0,0,0,3.428,3.428" transform="translate(-907.392 -618.385)" fill="#acc7eb" />
                  <path id="Path_8" data-name="Path 8" d="M1098.044,71.61c1.641-1.344-34.135-31.546-41.807-14.613,0,0-18.371-14.154-1.454-22.439,9.524-4.664,10.89-14.477,13.15-20.252s13.673-21.526,26.852-10.455c0,0,6.69,1.186,10.292,5.587,8.192,10.011,26.406,76.96-7.033,62.172" transform="translate(-978.253 -0.001)" fill="#163560" />
                  <path id="Path_9" data-name="Path 9" d="M1064.974,231.439a117.58,117.58,0,0,0,14.943,20.468c4.606,4.9,9.914,9.275,15.367,11.181a15.839,15.839,0,0,0,8.026.806c1.7-.3.962-10.348-1.4-17.988-3.116-10.063-12.83-35.068-12.83-35.068l-5.557,2.37s7.709,31.493,6.791,31.626l-8.152-20.629Z" transform="translate(-968.311 -171.247)" fill="#e5925a" />
                  <path id="Path_10" data-name="Path 10" d="M953.626,591.969s61.942-.985,70.206,4.271-8.261,89.191-8.261,89.191-16.021.511-17.294-3.729c0,0,2.882-39.33.36-46.124s-.967-16.21-.967-16.21-31.334,4.781-41.285,2.428-2.76-29.828-2.76-29.828" transform="translate(-868.839 -480.75)" fill="#ed7d2b" />
                  <path id="Path_11" data-name="Path 11" d="M1038.227,1034.68s-3.526,9.44-2.777,12.942-.459,10.29.974,13.326,3.993,7.448,1.536,8.565c-.932.424-11.779-7.99-12.4-10.472s-1.105-9.807-3.182-13.784-.336-7.183,2.665-8.645,7.183-9.47,7.183-9.47Z" transform="translate(-894.886 -834.266)" fill="#163560" />
                  <path id="Path_12" data-name="Path 12" d="M859.385,1137.126s3.4,9.487,6.228,11.679,6.288,8.158,9.342,9.552,7.856,3.114,6.7,5.553c-.438.925-14.154,1.495-16.231,0s-7.172-6.78-11.324-8.478-4.891-5.271-3.542-8.324-.622-11.87-.622-11.87Z" transform="translate(-705.524 -922.065)" fill="#2a4e96" />
                  <path id="Path_13" data-name="Path 13" d="M954.891,580.542s73.507,11.5,79.17,18.044,28.094,91.739,28.094,91.739-6.545,3.672-12.513.274c0,0-17.077-28.875-24.621-37.934s-10.438-37.085-11.681-37.368-40.8,4.777-55.371-4.867c-8.588-5.683-3.077-29.888-3.077-29.888" transform="translate(-905.229 -471.528)" fill="#f99746" />
                  <path id="Path_14" data-name="Path 14" d="M1168.508,233.893c4.338-3.266,17.392-6.872,25.557-3.2,3.866,1.74,9.733,4.02,13.244,8.382,3.789,4.708,8.152,20.629,8.152,20.629-1.427,8.386-8.094,9.413-8.094,9.413,3.9,14.762-14.49,10.905-13.3,19.092,2.074,14.285-11.29,21.787-18.573,25.116-9.531,4.358-36.319,9.85-36.348,0-.131-44.867,16.891-70.046,29.363-79.435" transform="translate(-1093.462 -186.12)" fill="#fff" />
                  <path id="Path_15" data-name="Path 15" d="M1220.3,339.177s4.831,26.074,17.917,33.729h12.314l-13-16.248Z" transform="translate(-1143.771 -275.487)" fill="#ebf3fa" />
                  <path id="Path_16" data-name="Path 16" d="M1127.387,165.089a63.19,63.19,0,0,0,4.7-6.206c.7-1.443-.611-7.658-2.241-7.618-1.029.026-2.295,3.79-3.419,5.159s-6.017,3.315-3.947,9.067c.233.648,4.907-.4,4.907-.4" transform="translate(-1007.057 -122.861)" fill="#e5925a" />
                  <path id="Path_17" data-name="Path 17" d="M1205.236,180.855c1.128,1.693-.249,6.437-6.645,4.96-4.2-.97-12.38-7.78-10.9-11.255.7-1.652,4.727-5.53,7.257-15.613l.732.349,12.313,5.871s-1.335,4.006-2.323,7.848c-.831,3.226-.9,7.14-.432,7.84" transform="translate(-1095.075 -129.101)" fill="#ffb27d" />
                  <path id="Path_18" data-name="Path 18" d="M1199.826,166.675s-1.335,4.006-2.323,7.848c-7.2-2.053-9.353-9.65-9.99-13.719Z" transform="translate(-1086.909 -130.609)" fill="#f4a269" />
                  <path id="Path_19" data-name="Path 19" d="M1157.912,80.753s-17.26,4-19.588-6.783-6.124-17.535,4.8-20.548,13.958.64,15.611,4.051,3.609,21.96-.821,23.28" transform="translate(-1037.985 -42.481)" fill="#ffb27d" />
                  <path id="Path_20" data-name="Path 20" d="M1205.76,42.156s-.109,12.414-9.251,18.44c-5.349,3.526-5.6,7.134-5.6,7.134s-3.167-10.623-3.671-18.613c0,0,13.652-12.218,18.523-6.961" transform="translate(-1092.792 -33.173)" fill="#163560" />
                  <path id="Path_21" data-name="Path 21" d="M1132.86,44.2s5.1,1.7,7.371,3.575c3.361,2.781,4.237,11.827,3.836,14.079,0,0,.916-15.721-4.145-19.726-5.366-4.247-7.062,2.072-7.062,2.072" transform="translate(-1020.978 -33.028)" fill="#163560" />
                  <path id="Path_22" data-name="Path 22" d="M1223.25,270.328a90.155,90.155,0,0,0,5.945,11.7c8.237,13.618,18.309,21.846,21.947,18.011,1.006-1.061,3.546-2.6.891-8.3-2.051-4.4-4.591-9.914-7.082-15.335-5.137-11.179-10.065-21.986-10.065-21.986-1.568,0-18.071.574-11.636,15.919" transform="translate(-1146.722 -206.636)" fill="#ffb27d" />
                  <path id="Path_23" data-name="Path 23" d="M1057.877,432.219c.564-.338,31.62,7.2,41.238,9.552l2.808.687v3.624s-32.134,1.508-37.153-.8-9.81-7.669-9.81-7.669Z" transform="translate(-964.117 -351.049)" fill="#ffb27d" />
                  <path id="Path_24" data-name="Path 24" d="M1267.212,270.328a90.155,90.155,0,0,0,5.945,11.7,45.8,45.8,0,0,0,15.756-5.629c-5.137-11.179-10.065-21.986-10.065-21.986-1.568,0-18.071.574-11.636,15.919" transform="translate(-1190.684 -206.636)" fill="#fff" />
                  <path id="Path_25" data-name="Path 25" d="M788.588,494.223h-34.5a2.75,2.75,0,0,1,2.75-2.75h31.747Z" transform="translate(-594.284 -399.185)" fill="#ed7d2b" />
                  <path id="Path_26" data-name="Path 26" d="M483.725,306.039H537.06L524.267,343.61H470.586Z" transform="translate(-289.52 -248.571)" fill="#ed7d2b" />
                  <path id="Path_27" data-name="Path 27" d="M532.878,306.035l-12.793,37.571H466.4l13.139-37.571Z" transform="translate(-284.552 -248.568)" fill="#f99746" />
                  <path id="Path_28" data-name="Path 28" d="M624.436,392.985a3.468,3.468,0,0,1-3.2,2.6,2,2,0,0,1-2-2.6,3.468,3.468,0,0,1,3.2-2.6,2,2,0,0,1,2,2.6" transform="translate(-404.865 -317.08)" fill="#fff" />
                  <path id="Path_29" data-name="Path 29" d="M63.441,887.72s-9.285-10.833-.573-17.713c0,0,6.364-3.853,11.554-3.54,0,0-7.353,12.33-4.974,13.557s7.2-14.33,7.2-14.33,14.482-1.4,19.141.836c0,0-12.381,10.132-9.773,10.735S102,867.922,102,867.922s13.479,2.294,12.761,10.517-5.363,10.762-7.4,10.543-13.982-4.777-14.243-3.1,6.928,7.139,11.961,7.395c0,0-9.5,10.481-15.819,7.456s-6.855-7.82-10.792-9.618-7.3-1.794-5.672.04,8.751,3.616,11,7.6,4.272,6.669-2.263,5.685S64.373,898.67,63.907,893.2Z" transform="translate(210.015 -702.781)" fill="#c8dbf4" />
                  <path id="Path_30" data-name="Path 30" d="M147.4,936.355s-36.611-1.411-44.7,9.059a26.478,26.478,0,0,1-7.29,10.231l-.858,2.337s6.344-6.689,8.614-7.094c0,0-3.55-12.972,44.229-14.534" transform="translate(170.751 -760.475)" fill="#c8dbf4" />
                  <path id="Path_31" data-name="Path 31" d="M368.8,1035.375a22.99,22.99,0,0,0-8.4,16.2l1.426.128s2.51-10.737,7.046-14.936-.068-1.388-.068-1.388" transform="translate(-102.635 -840.205)" fill="#c8dbf4" />
                  <path id="Path_32" data-name="Path 32" d="M507.933,746.881s14.771-8.271,7.878-19.5c0,0-5.514-6.893-11.422-8.665,0,0,3.151,16.74,0,17.134s-2.166-18.906-2.166-18.906-15.558-7.484-21.663-6.893c0,0,9.65,16.346,6.5,15.952s-13.983-16.937-13.983-16.937-15.952-2.954-18.512,6.5,1.575,14.18,3.939,14.77,17.528.394,17.134,2.363-10.635,5.12-16.346,3.348c0,0,6.3,15.558,14.574,14.771s10.832-5.908,15.952-6.3,8.862.985,6.3,2.363-11.226.452-15.361,3.968-7.484,5.682.2,7.258,21.467.591,24.224-5.317Z" transform="translate(-266.942 -575.565)" fill="#c8dbf4" />
                  <path id="Path_33" data-name="Path 33" d="M484.491,761.3s41.357,13.392,46.084,28.359a31.419,31.419,0,0,0,3.939,14.377v2.954s-4.333-10.044-6.7-11.423c0,0,9.256-13-43.327-34.268" transform="translate(-289.584 -618.344)" fill="#c8dbf4" />
                  <path id="Path_34" data-name="Path 34" d="M465.9,980.934s6.378,9.685,2.742,21.475l-1.641-.441s1.593-12.986-1.744-19.517.643-1.518.643-1.518" transform="translate(-220.968 -795.242)" fill="#c8dbf4" />
                  <path id="Path_35" data-name="Path 35" d="M12.8,633.49s-19.47-10.9-10.384-25.7c0,0,7.269-9.086,15.057-11.422,0,0-4.154,22.066,0,22.585s2.856-24.922,2.856-24.922,20.509-9.865,28.556-9.086c0,0-12.721,21.547-8.567,21.028S58.75,583.646,58.75,583.646s21.028-3.894,24.4,8.567-2.077,18.692-5.192,19.47-23.1.519-22.586,3.115,14.018,6.75,21.547,4.413c0,0-8.307,20.509-19.211,19.47s-14.278-7.788-21.028-8.307-11.682,1.3-8.307,3.115,14.8.6,20.249,5.23,9.865,7.49-.26,9.567-28.3.779-31.931-7.009Z" transform="translate(251.69 -473.581)" fill="#d4e4f8" />
                  <path id="Path_36" data-name="Path 36" d="M122.762,652.5S68.245,670.15,62.015,689.88a41.416,41.416,0,0,1-5.192,18.951v3.894s5.711-13.24,8.826-15.057c0,0-12.2-17.134,57.113-45.171" transform="translate(202.475 -529.972)" fill="#d4e4f8" />
                  <path id="Path_37" data-name="Path 37" d="M399.2,942.016s-8.407,12.767-3.614,28.308l2.163-.581s-2.1-17.117,2.3-25.727-.848-2-.848-2" transform="translate(-139.898 -763.156)" fill="#d4e4f8" />
                  <path id="Path_38" data-name="Path 38" d="M218.853,1117.756a38.139,38.139,0,0,0,38.139-38.139H180.714a38.139,38.139,0,0,0,38.139,38.139" transform="translate(44.981 -876.887)" fill="#d4e4f8" />
                  <path id="Path_39" data-name="Path 39" d="M255.24,1272.705H177.908a4.705,4.705,0,0,1,4.705-4.705h67.921a4.705,4.705,0,0,1,4.705,4.705" transform="translate(47.26 -1029.896)" fill="#d4e4f8" />
                  <rect id="Rectangle_8831" data-name="Rectangle 8831" width="78.4" height="1.17" transform="translate(224.441 202.73)" fill="#9fc1f4" />
                  <path id="Path_40" data-name="Path 40" d="M630.617,514.571h-179.5a3.218,3.218,0,0,1-3.218-3.219v-2.016a3.218,3.218,0,0,1,3.218-3.218h179.5a3.218,3.218,0,0,1,3.218,3.218v2.016a3.218,3.218,0,0,1-3.218,3.219" transform="translate(-382.035 -411.08)" fill="#2a4e96" />
                  <path id="Path_41" data-name="Path 41" d="M492.863,672.088l.1-.012a2.146,2.146,0,0,0,1.894-2.387L476.623,528.626l-10.219.293L490.91,670.67a1.752,1.752,0,0,0,1.953,1.418" transform="translate(-246.549 -429.361)" fill="#2a4e96" />
                  <path id="Path_42" data-name="Path 42" d="M1285.591,672.088l-.1-.012a2.146,2.146,0,0,1-1.894-2.387l18.235-141.063,10.219.293L1287.545,670.67a1.752,1.752,0,0,1-1.953,1.418" transform="translate(-1217.174 -429.361)" fill="#2a4e96" />
                  <path id="Path_43" data-name="Path 43" d="M967.94,473.322s10.23-2.939,13.077-2.127c2.008.573,4.574,3.544,5.911,5.244a.752.752,0,0,1-.6,1.216c-1.712-.018-4.48-.13-5.929-.891-1.367-.718-3.4.864-5.67.874-1.741.008-3.98,0-3.98,0Z" transform="translate(-832.941 -382.599)" fill="#ffb27d" />
                </g>
              </g>
              <g id="Admin-2" data-name="Admin" transform="translate(-221.776 -6496.716)">
                <rect id="Rectangle_298" data-name="Rectangle 298" width={179} height={43} rx="21.5" transform="translate(1307.776 11718.812)" />
                <text id="Admin_Dashboard" data-name="Admin Dashboard" transform="translate(1397.776 11744.812)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-61.565" y={0}>Admin Dashboard</tspan></text>
              </g>
              <g id="Register" transform="translate(-346 967.838)">
                <g id="User-2" data-name="User" transform="translate(-380.685 -7537.5)">
                  <rect id="Rectangle_298-2" data-name="Rectangle 298" width={141} height={43} rx="21.5" transform="translate(1330 11719)" />
                  <text id="User-3" data-name="User" transform="translate(1401 11745)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-15.414" y={0}>User</tspan></text>
                </g>
              </g>
              <text id="Connect_to_Web3_Wallet" data-name="Connect to 
  Web3 Wallet" transform="translate(685 5298.526)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x={0} y={0}>Connect to </tspan><tspan fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={20}>Web3 Wallet</tspan></tspan></text>
              <g id="_2fa" data-name="2fa" transform="translate(26 -93)">
                <g id="Rectangle_45" data-name="Rectangle 45" transform="translate(400.954 5503.829)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="352.389" height={70} rx={25} stroke="none" />
                  <rect x={1} y={1} width="350.389" height={68} rx={24} fill="none" />
                </g>
                <text id="View_Swap_or_Exchange_Pairs" data-name="View Swap or Exchange Pairs" transform="translate(576.954 5543.829)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x="-118.24" y={0}>View </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Swap or Exchange Pairs</tspan></text>
              </g>
              <g id="_2fa-2" data-name="2fa" transform="translate(3 9)">
                <g id="Rectangle_8830" data-name="Rectangle 8830" transform="translate(1085.954 5410.829)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="352.389" height={68} rx={25} stroke="none" />
                  <rect x={1} y={1} width="350.389" height={66} rx={24} fill="none" />
                </g>
                <text id="Manage_Swap_Pairs" data-name="Manage Swap Pairs" transform="translate(1261.954 5449.829)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700} letterSpacing="0.03em"><tspan x="-79.56" y={0}>Manage Swap Pairs</tspan></text>
              </g>
              <g id="KYC" transform="translate(-96 4773.17)">
                <g id="Rectangle_45-2" data-name="Rectangle 45" transform="translate(571 785)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Choose_the_Pair_to_Swap" data-name="Choose the Pair to Swap" transform="translate(698 821)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-80.885" y={0}>Choose the </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Pair to Swap</tspan></text>
              </g>
              <g id="User_Dashboard" data-name="User Dashboard" transform="translate(32.194 5007.194)">
                <g id="Rectangle_45-3" data-name="Rectangle 45" transform="translate(539 785.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={319} height={82} rx={25} stroke="none" />
                  <rect x={1} y={1} width={317} height={80} rx={24} fill="none" />
                </g>
                <text id="Swap_Completion_or_Asset_Received_to_Web_3_Wallet_" data-name="Swap Completion or Asset 
  Received to Web 3 Wallet " transform="translate(699 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-91.301" y={0}>Swap Completion or Asset </tspan><tspan x="-86.1" y={21}>Received</tspan><tspan y={21} xmlSpace="preserve" fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}> to Web 3 Wallet </tspan></text>
              </g>
              <g id="My_Profile" data-name="My Profile" transform="translate(32.194 4925.194)">
                <g id="Rectangle_45-4" data-name="Rectangle 45" transform="translate(571 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Slippage_Tolerance_Settings" data-name="Slippage Tolerance Settings" transform="translate(699 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-92.183" y={0}>Slippage Tolerance</tspan><tspan y={0} xmlSpace="preserve" fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}> Settings</tspan></text>
              </g>
              <g id="User_Balance" data-name="User Balance" transform="translate(563.694 4842.38)">
                <g id="Rectangle_45-5" data-name="Rectangle 45" transform="translate(571.259 758.524)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={64} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={62} rx={24} fill="none" />
                </g>
                <text id="Add_or_Remove_Liquidity_Pairs" data-name="Add or Remove Liquidity Pairs" transform="translate(698.259 796.524)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-100.149" y={0}>Add or Remove </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Liquidity Pairs</tspan></text>
              </g>
              <g id="User_Balance-2" data-name="User Balance" transform="translate(563.694 4977.241)">
                <g id="Rectangle_45-6" data-name="Rectangle 45" transform="translate(573.259 754.524)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Admin_Revenue_Settings" data-name="Admin Revenue Settings" transform="translate(700.259 790.524)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-82.313" y={0}>Admin </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Revenue Settings</tspan></text>
              </g>
              <g id="Admin_Disputes" data-name="Admin Disputes" transform="translate(569 5147.095)">
                <circle id="Ellipse_134-2" data-name="Ellipse 134" cx="7.5" cy="7.5" r="7.5" transform="translate(689 521)" fill="#ff6464" />
                <circle id="Ellipse_1375" data-name="Ellipse 1375" cx="7.5" cy="7.5" r="7.5" transform="translate(689 648)" fill="#ff6464" />
              </g>
              <g id="Website_Content" data-name="Website Content" transform="translate(551 5089.095)">
                <g id="Rectangle_45-7" data-name="Rectangle 45" transform="translate(604 785)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={224} height={63} rx={20} stroke="none" />
                  <rect x={1} y={1} width={222} height={61} rx={19} fill="none" />
                </g>
                <g id="Group_27450" data-name="Group 27450" transform="translate(17 2)">
                  <text id="View_Swap_History" data-name="View Swap History" transform="translate(699 821)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-62.391" y={0}>View </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Swap History</tspan></text>
                </g>
              </g>
              <g id="Complete_Trade" data-name="Complete Trade" transform="translate(564 5286.258)">
                <g id="Rectangle_45-8" data-name="Rectangle 45" transform="translate(571 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Select_Pair_to_Add_Liquidity" data-name="Select Pair to Add Liquidity" transform="translate(699 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-90.734" y={0}>Select </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Pair to Add Liquidity</tspan></text>
              </g>
              <g id="Complete_Trade-2" data-name="Complete Trade" transform="translate(564 5403.258)">
                <g id="Rectangle_45-9" data-name="Rectangle 45" transform="translate(547 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={303} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={301} height={60} rx={24} fill="none" />
                </g>
                <text id="Connect_Wallet_and_Deposit_Token" data-name="Connect Wallet and Deposit Token" transform="translate(699 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-114.037" y={0}>Connect Wallet </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>and Deposit Token</tspan></text>
              </g>
              <g id="Complete_Trade-3" data-name="Complete Trade" transform="translate(564 5495.258)">
                <g id="Rectangle_45-10" data-name="Rectangle 45" transform="translate(547 813.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={303} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={301} height={60} rx={24} fill="none" />
                </g>
                <text id="Earn_Up_Tokens_and_Trade_Fee" data-name="Earn Up Tokens and Trade Fee" transform="translate(699 849.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-98.539" y={0}>Earn Up Tokens </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>and Trade Fee</tspan></text>
              </g>
              <g id="Complete_Trade-4" data-name="Complete Trade" transform="translate(271 5150.429)">
                <g id="Rectangle_45-11" data-name="Rectangle 45" transform="translate(571 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Add_New_Liquidity" data-name="Add New Liquidity" transform="translate(699 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-63.588" y={0}>Add New Liquidity</tspan></text>
              </g>
              <g id="Complete_Trade-5" data-name="Complete Trade" transform="translate(-13.5 5286.258)">
                <g id="Rectangle_45-12" data-name="Rectangle 45" transform="translate(604.454 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={189} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={187} height={60} rx={24} fill="none" />
                </g>
                <text id="Earn" transform="translate(698.454 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-15.295" y={0}>Earn</tspan></text>
              </g>
              <g id="Complete_Trade-6" data-name="Complete Trade" transform="translate(-218.239 5403.429)">
                <g id="Rectangle_45-13" data-name="Rectangle 45" transform="translate(604.454 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="248.865" height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width="246.865" height={60} rx={24} fill="none" />
                </g>
                <text id="Yield_Farming" data-name="Yield Farming" transform="translate(728.198 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-47.089" y={0}>Yield Farming</tspan></text>
              </g>
              <g id="Complete_Trade-7" data-name="Complete Trade" transform="translate(131.375 5403.258)">
                <g id="Rectangle_45-14" data-name="Rectangle 45" transform="translate(604.454 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="248.865" height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width="246.865" height={60} rx={24} fill="none" />
                </g>
                <text id="Syrup_Pool" data-name="Syrup Pool" transform="translate(728.198 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-36.505" y={0}>Syrup Pool</tspan></text>
              </g>
              <g id="Complete_Trade-8" data-name="Complete Trade" transform="translate(-218.239 5495.429)">
                <g id="Rectangle_45-15" data-name="Rectangle 45" transform="translate(604.454 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="248.865" height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width="246.865" height={60} rx={24} fill="none" />
                </g>
                <text id="Find_Form" data-name="Find Form" transform="translate(728.198 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-34.006" y={0}>Find Form</tspan></text>
              </g>
              <g id="Complete_Trade-9" data-name="Complete Trade" transform="translate(131.375 5495.258)">
                <g id="Rectangle_45-16" data-name="Rectangle 45" transform="translate(604.454 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="248.865" height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width="246.865" height={60} rx={24} fill="none" />
                </g>
                <text id="Find_Pool" data-name="Find Pool" transform="translate(728.198 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-31.71" y={0}>Find Pool</tspan></text>
              </g>
              <g id="Complete_Trade-10" data-name="Complete Trade" transform="translate(-218.239 5587.429)">
                <g id="Rectangle_45-17" data-name="Rectangle 45" transform="translate(604.454 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="248.865" height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width="246.865" height={60} rx={24} fill="none" />
                </g>
                <text id="Stake_up_Tokens" data-name="Stake up Tokens" transform="translate(728.198 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-54.747" y={0}>Stake up Tokens</tspan></text>
              </g>
              <g id="Complete_Trade-11" data-name="Complete Trade" transform="translate(131.375 5587.429)">
                <g id="Rectangle_45-18" data-name="Rectangle 45" transform="translate(604.454 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="248.865" height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width="246.865" height={60} rx={24} fill="none" />
                </g>
                <text id="Connect_Wallet" data-name="Connect Wallet" transform="translate(728.198 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-51.632" y={0}>Connect Wallet</tspan></text>
              </g>
              <g id="Complete_Trade-12" data-name="Complete Trade" transform="translate(-218.239 5679.429)">
                <g id="Rectangle_45-19" data-name="Rectangle 45" transform="translate(604.454 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="248.865" height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width="246.865" height={60} rx={24} fill="none" />
                </g>
                <text id="Harvest_your_Rewards" data-name="Harvest your Rewards" transform="translate(728.198 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-74.438" y={0}>Harvest your Rewards</tspan></text>
              </g>
              <g id="Complete_Trade-13" data-name="Complete Trade" transform="translate(131.375 5679.429)">
                <g id="Rectangle_45-20" data-name="Rectangle 45" transform="translate(604.454 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="248.865" height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width="246.865" height={60} rx={24} fill="none" />
                </g>
                <text id="Stake_Tokens" data-name="Stake Tokens" transform="translate(728.198 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-44.1" y={0}>Stake Tokens</tspan></text>
              </g>
              <g id="Complete_Trade-14" data-name="Complete Trade" transform="translate(131.375 5773.429)">
                <g id="Rectangle_45-21" data-name="Rectangle 45" transform="translate(573.454 782.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="310.865" height={66} rx={25} stroke="none" />
                  <rect x={1} y={1} width="308.865" height={64} rx={24} fill="none" />
                </g>
                <text id="Earn_or_Harvest_Disered_Token" data-name="Earn or Harvest Disered Token" transform="translate(728.198 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-102.186" y={0}>Earn or Harvest Disered Token</tspan></text>
              </g>
            </g>
          </g>
        </svg>
              )}
          { isMobile && (
          <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/decentralized-exchange-mobile.webp" 
              alt="Dex-workflow"
              width={392} />
              )}
        
      </div>
    </section>
  )
}

export default HowOur